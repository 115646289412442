import { defineComponent, ref, Ref } from '@vue/composition-api';

import FetchData from '@/components/FetchData';

interface Compat {
	[propName: string]: string | number | boolean;
}

interface DataList {
	readonly onlyid: string | number;
	title: string;
	description?: string;
	useCode: string[] | string;
	compat: Compat;
}

export default defineComponent({
	components: { FetchData },
	setup() {
		const dataList: Ref<DataList[]> = ref([
			{
				onlyid: '$$$$asoduqw089edq2',
				title: '标题占位符',
				useCode: ['html'],
				compat: {
					browser: true,
					browser1: true,
				},
			},
			{
				onlyid: '$$$$iosadopqwpop',
				title: '标题占位符标题',
				useCode: ['vbscript'],
				compat: {
					browser: '5.0 - 10.0',
					browser1: true,
				},
			},
		]);
		const touchData = (data: DataList[]) => (dataList.value = data);
		return { dataList, touchData };
	},
});
/*

{
				onlyid: 'asduhwuiuisdafqw',
				title: '网页进出特效',
				useCode: ['html'],
				compat: {
					IE: '6.0 - 8.0',
					Firefox: false,
					Edge: false,
					Chrome: false,
				},
			},
			{
				onlyid: 'ioasduioqw',
				title: '打字特效',
				useCode: ['vbscript'],
				compat: {
					IE: '5.0 - 10.0',
					Firefox: false,
					Edge: false,
					Chrome: false,
				},
			},
			{
				onlyid: 'try654det',
				title: '蚂蚁爬行特效',
				useCode: ['javascript', 'css'],
				compat: {
					IE: '6.0+',
					Firefox: '2.0 - 3.5',
					Edge: 12,
					Chrome: false,
				},
			},
			{
				onlyid: '54dfgvsw',
				title: '网页外部弹窗效果',
				useCode: ['javascript'],
				compat: {
					IE: '6.0 - 10.0',
					Firefox: false,
					Edge: false,
					Chrome: false,
				},
			},
*/
